import React, {useEffect} from 'react';
import './assets/styles/Animace.scss';
import Lottie from "lottie-react";
import deadline from "./DEAD_INE_json.json";
import Deadline from './deadline.svg';
import Soon from './soon.svg';

const Logos = () => {
  return (
    <div className="logos">
      <img className="logos__soon hidden" id="soon" alt="logo" src={Soon} />
      <a className="logos__link" target="_blank" href="https://instagram.com/dead_ine?utm_medium=copy_link" rel="nofollow">
        <img className="logos__deadline hidden" id="deadline" alt="logo" src={Deadline} />
      </a>
    </div>
  )
}

function Animace() {
  

  useEffect(() => {
    const timer = setTimeout(() => {
      let deadline = document.getElementById("deadline");
      let soon = document.getElementById("soon");
      deadline.classList.remove("hidden");
      deadline.classList.add("visible");
      soon.classList.remove("hidden");
      soon.classList.add("visible");
    }, 3000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="animace">
      <Lottie animationData={deadline} className="lottie" loop={false}>
      </Lottie>
      <Logos />
    </div>
  )
}

export default Animace
